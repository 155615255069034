import { useCallback, useEffect, useRef, useState } from 'react'

const isBrowser = typeof window !== 'undefined' && window.document

const loadGoogleMapScript = (googleMapsScriptBaseUrl, googleMapsScriptUrl) => {
  if (!isBrowser) return Promise.resolve()

  if (typeof google !== 'undefined') {
    if (window?.google.maps && window?.google.maps.api) return Promise.resolve()
  }

  const scriptElements = document.querySelectorAll(`script[src*="${googleMapsScriptBaseUrl}"]`)

  if (scriptElements && scriptElements.length) {
    return new Promise(resolve => {
      // in case we already have a script on the page and it's loaded we resolve
      if (typeof google !== 'undefined') return resolve()

      // otherwise we wait until it's loaded and resolve
      scriptElements[0].addEventListener('load', () => resolve())
    })
  }

  let scriptUrl = new URL(googleMapsScriptUrl)
  scriptUrl.searchParams.set('callback', '__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__')
  const el = document.createElement('script')
  el.src = scriptUrl.toString()

  return new Promise(resolve => {
    window.__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__ = resolve
    document.body.appendChild(el)
  })
}

export default function usePlacesAutocompleteService({
  apiKey,
  libraries = 'places',
  googleMapsScriptBaseUrl = 'https://maps.googleapis.com/maps/api/js',
  options = {},
  sessionToken,
  language
}) {
  const languageQueryParam = language ? `&language=${language}` : ''
  const googleMapsScriptUrl = `${googleMapsScriptBaseUrl}?key=${apiKey}&libraries=${libraries}${languageQueryParam}`
  const [placePredictions, setPlacePredictions] = useState([])
  const [isPlacePredsLoading, setIsPlacePredsLoading] = useState(false)
  const [placeInputValue, setPlaceInputValue] = useState(null)
  const [isQueryPredsLoading, setIsQueryPredsLoading] = useState(false)
  const [queryInputValue, setQueryInputValue] = useState(false)
  const [queryPredictions, setQueryPredictions] = useState([])
  const placesAutocompleteService = useRef(null)
  const placesService = useRef(null)
  const autocompleteSession = useRef(null)
  const handleLoadScript = useCallback(
    () => loadGoogleMapScript(googleMapsScriptBaseUrl, googleMapsScriptUrl),
    [googleMapsScriptBaseUrl, googleMapsScriptUrl]
  )

  const debouncedPlacePredictions = optionsArg => {
    if (placesAutocompleteService.current && optionsArg.input)
      placesAutocompleteService.current.getPlacePredictions(
        {
          ...(sessionToken && autocompleteSession.current ? { sessionToken: autocompleteSession.current } : {}),
          ...options,
          ...optionsArg
        },
        r => {
          setIsPlacePredsLoading(false)
          setPlacePredictions(r || [])
        }
      )
  }

  const debouncedQueryPredictions = optionsArg => {
    if (placesAutocompleteService.current && optionsArg.input)
      placesAutocompleteService.current.getQueryPredictions(
        {
          ...(sessionToken && autocompleteSession.current ? { sessionToken: autocompleteSession.current } : {}),
          ...options,
          ...optionsArg
        },
        r => {
          setIsQueryPredsLoading(false)
          setQueryPredictions(r || [])
        }
      )
  }

  useEffect(() => {
    if (!isBrowser) return

    const buildService = () => {
      // eslint-disable-next-line no-undef
      if (!google) return console.error('Google has not been found. Make sure your provide apiKey prop.')

      // eslint-disable-next-line no-undef
      placesAutocompleteService.current = new google.maps.places.AutocompleteService()

      // eslint-disable-next-line no-undef
      placesService.current = new google.maps.places.PlacesService(document.createElement('div'))

      if (sessionToken) autocompleteSession.current = new window.google.maps.places.AutocompleteSessionToken()
    }

    if (apiKey) {
      handleLoadScript().then(() => buildService())
    } else {
      buildService()
    }
  }, [])

  return {
    placesService: placesService.current,
    autocompleteSessionToken: autocompleteSession.current,
    placesAutocompleteService: placesAutocompleteService.current,
    placePredictions: placeInputValue ? placePredictions : [],
    isPlacePredictionsLoading: isPlacePredsLoading,
    getPlacePredictions: opt => {
      if (opt.input) {
        setPlaceInputValue(opt.input)
        setIsPlacePredsLoading(true)
        debouncedPlacePredictions(opt)
        return
      }
      setPlacePredictions([])
      setPlaceInputValue(null)
      debouncedPlacePredictions(opt)
      setIsPlacePredsLoading(false)
    },
    queryPredictions: queryInputValue ? queryPredictions : [],
    isQueryPredictionsLoading: isQueryPredsLoading,
    getQueryPredictions: opt => {
      if (opt.input) {
        setQueryInputValue(opt.input)
        setIsQueryPredsLoading(true)
        debouncedQueryPredictions(opt)
        return
      }
      setQueryPredictions([])
      setQueryInputValue(null)
      debouncedQueryPredictions(opt)
      setIsQueryPredsLoading(false)
    },
    refreshSessionToken: () => {
      autocompleteSession.current = new window.google.maps.places.AutocompleteSessionToken()
    }
  }
}
