import React, { useEffect, useRef, useState } from 'react'
import { StyledAutocomplete } from './styled'
import Input from '../TextField'
import useDebounce from '../../hooks/useDebounce'
import usePlacesAutocompleteService from '../../hooks/usePlacesAutocomplete'
import { SchoolService } from '../../services/School.service'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import { Box } from '@mui/material'

const CustomSchoolSnippet = () => {
  const countryHidden = useRef(null)
  const schoolNameHidden = useRef(null)
  const cityHidden = useRef(null)
  const placeId = useRef(null)

  const ref = useRef(null)

  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [mostUsedSchools, setMostUsedSchools] = useState([])

  const { placesService, placePredictions, getPlacePredictions } = usePlacesAutocompleteService({
    apiKey: 'AIzaSyC-NDIFH3gVOSOPxZ5p8twSVNzSagoVQgM',
    language: 'en',
    options: {
      types: ['school', 'secondary_school', 'university'],
    },
  })

  const debouncedSearchValue = useDebounce(inputValue, 500)

  useEffect(() => {
    getPlacePredictions({ input: debouncedSearchValue })
  }, [debouncedSearchValue])

  useEffect(() => {
    const fetch = async () => {
      const data = await SchoolService.getMostUsedSchools()

      setMostUsedSchools(data)
    }

    fetch()
  }, [])

  useEffect(() => {
    if (ref.current) {
      const parentForm = ref.current?.form

      if (parentForm) {
        const schoolCountryInputHidden = parentForm.querySelector('.immerse-school-country input')
        const schoolNameInputHidden = parentForm.querySelector('.immerse-school-name input')
        const schoolCityInputHidden = parentForm.querySelector('.immerse-school-city input')
        const schoolPlaceIdInputHidden = parentForm.querySelector('.immerse-school-id input')

        countryHidden.current = schoolCountryInputHidden
        schoolNameHidden.current = schoolNameInputHidden
        cityHidden.current = schoolCityInputHidden
        placeId.current = schoolPlaceIdInputHidden
      }
    }
  }, [ref.current])

  return (
    <Box>
      <input ref={ref} style={{ display: 'none' }} />

      <StyledAutocomplete
        value={value}
        onChange={(event, newValue) => {
          if (newValue) {
            placesService?.getDetails(
              {
                placeId: newValue.place_id || newValue.googlePlacesId,
              },
              (placeDetails) => {
                const locality = placeDetails.address_components.filter(
                  (f) => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']),
                )[0]?.long_name

                const state = placeDetails.address_components.filter(
                  (f) =>
                    JSON.stringify(f.types) ===
                    JSON.stringify(['administrative_area_level_1', 'political']),
                )[0]?.long_name

                const schoolCountry = placeDetails.address_components.filter(
                  (f) => JSON.stringify(f.types) === JSON.stringify(['country', 'political']),
                )[0]?.long_name

                let city = locality || state

                if (placeId.current) placeId.current.value = placeDetails.place_id
                if (schoolNameHidden.current)
                  schoolNameHidden.current.value =
                    newValue?.structured_formatting?.main_text || newValue.schoolName || ''
                if (cityHidden.current) cityHidden.current.value = city || ''
                if (countryHidden.current) countryHidden.current.value = schoolCountry || ''
              },
            )
          }

          setValue(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        id='school-autocomplete'
        fullWidth
        options={!debouncedSearchValue ? mostUsedSchools : placePredictions}
        getOptionLabel={(o) => o.description || o.schoolName}
        disablePortal
        renderInput={(params) => {
          return (
            <Input
              {...params}
              value={params.inputProps.value}
              onChange={params.inputProps.onChange}
              placeholder={'Enter a school'}
              InputProps={{
                ...params.InputProps,
                className: '',
                endAdornment: <SelectAngleIcon onClick={params.InputProps.onClick} />,
              }}
              inputProps={{ ...params.inputProps, className: '' }}
            />
          )
        }}
        slotProps={{
          popper: {
            sx: { zIndex: 100000000 },
          },
          paper: { elevation: 8, sx: { backgroundColor: 'transparent' } },
          clearIndicator: { sx: { display: 'none' } },
        }}
      />
    </Box>
  )
}

export default CustomSchoolSnippet
