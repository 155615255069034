import { Box, Autocomplete } from '@mui/material'
import styled from 'styled-components'

export const StyledSchoolSnippet = styled(Box)`
  .two-columns {
    display: flex;
    gap: 3.2rem;
  }

  .column {
    flex-basis: 50%;
  }

  .add-form {
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #1d1d1d;
      }
    }

    &-delete {
      padding: 4px 30px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      min-width: auto;
      margin-left: 30px;
    }
  }

  .form {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 0px;
    }

    &-col {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      width: 76.8rem;

      @media (max-width: 1200px) {
        width: 55.8rem;
      }
      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    &-item {
      flex-basis: calc(50% - 0.8rem);

      @media (max-width: 768px) {
        flex-basis: 100%;
      }
    }

    &-dates {
      flex-basis: calc(50% - 0.8rem);

      @media (max-width: 768px) {
        flex-basis: 100%;
      }

      &-container {
        display: flex;
        gap: 0.8rem;
      }

      &-item {
        flex-basis: 33.33333%;
      }

      &-label {
        ${({ theme }) => theme.typography.textSecondary};
        margin-bottom: 0.8rem;

        &-error {
          color: #c8102e;
        }
      }
    }

    &-submit-button {
      display: flex;
      justify-content: flex-end;
      max-width: 76.8rem;
      width: 100%;

      @media (max-width: 1200px) {
        width: 55.8rem;
      }
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  .school-block {
    display: flex;
    align-items: center;
    padding: 12px 8px;
    background-color: #f5f5f7;
    border-radius: 12px;

    &-undo-button {
      min-width: 32px;
      min-height: 32px;
      max-width: 32px;
      max-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #2d4960;
      border: none;
      border-radius: 6px;
      transition: background-color 0.1s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #181c2a;
      }

      svg {
        path {
          stroke: #ffffff;
        }
      }
    }

    &-text {
      margin: 0 0 0 16px !important;
      font-size: 18px;
    }
  }
`

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      padding: 0;
      padding-right: 12px !important;
    }

    .MuiSvgIcon-root {
      transition: transform 0.1s ease-in-out;
    }

    input[aria-expanded='true'] {
      ~ .MuiSvgIcon-root {
        transform: rotate(-180deg);
      }
    }

    input[aria-expanded='true'] {
      ~ .search-icon {
        transform: rotate(0deg);
      }
    }

    .search-icon {
      path {
        fill: #c9c9c9;
      }
    }

    .Mui-focused {
      path {
        fill: #181c2a;
      }
    }
  }
`
